<template>
  <InputGroup
    :beforeWidth="104"
  >
    <template #before>
      <InputSelect
        v-model="prefix"
        autocomplete="off"
        @change="handleChange()"
        @blur="$emit('blur')"
        :options="optionsPrefix"
      />
    </template>

    <InputText
      v-model="number"
      type="tel"
      autocomplete="tel-national"
      @change="normalizeNumber(); handleChange();"
      @blur="$emit('blur')"
    />

  </InputGroup>
</template>

<script>
import InputGroup from '@gds/components/InputGroup';
import InputText from '@gds/components/InputText';
import InputSelect from '@gds/components/InputSelect';

export default {
  name: 'InputEmail',
  components: {
    InputGroup,
    InputText,
    InputSelect,
  },
  data() {
    return {
      prefix: this.value.prefix,
      number: this.value.number,
    };
  },
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    fullValue() {
      return { prefix: this.prefix, number: this.number };
    },
    optionsPrefix: () => [
      { value: '+421', label: '+421' },
      { value: '+420', label: '+420' },
      { value: '+48', label: '+48' },
      { value: '+380', label: '+380' },
      { value: '+36', label: '+36' },
      { value: '+43', label: '+43' },
    ],
  },
  methods: {
    normalizeNumber() {
      if (this.number.startsWith('0')) {
        this.number = this.number.substring(1);
      }
    },
    handleInput() {
      this.$emit('input', this.fullValue);
    },
    handleChange() {
      this.$emit('change', this.fullValue);
    },
  },
  watch: {
    fullValue() {
      this.handleInput();
    },
  },
};
</script>
