<template>
  <transition
    name="fade"
    :duration="300"
  >
    <div
      v-show="isOpen"
      class="modal-space"
      role="dialog"
      @keydown="handleKeyDown"
      tabindex="-1"
    >
      <div class="modal-scrim" @click="closeMe()"></div>
        <div
          class="modal-window"
          role="document"
          v-if="isOpen"
        >
          <header class="header">
            <h2 class="title">{{ title }}</h2>
            <button v-if="closable" class="close" aria-label="Zatvoriť" @click="closeMe()">
              <span aria-hidden="true">&times;</span>
            </button>
          </header>
          <div class="body modal-body">
            <slot />
          </div>
          <footer class="footer" v-if="$slots.footer">
            <slot name="footer" />
          </footer>
        </div>
    </div>
  </transition>
</template>

<script>
import './ModalWindow.scss';

export default {
  name: 'ModalWindow',
  data() {
    return {
      modalPluginInstalled: !!this.$modal,
      isOpen: false,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    closable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    openTrigger() {
      if (this.modalPluginInstalled) {
        return this.$modal.state[this.name];
      }
      return false;
    },
  },
  watch: {
    openTrigger(newVal) {
      this.isOpen = newVal;
    },
  },
  methods: {
    handleKeyDown(event) {
      const { key } = event;

      switch (key) {
        case 'Esc': // IE/Edge
        case 'Escape': {
          this.closeMe();
          break;
        }
        default:
      }
    },
    closeMe() {
      if (this.closable) {
        this.$modal.hide(this.name);
      }
    },
  },
  created() {
    if (this.modalPluginInstalled) {
      this.$set(this.$modal.state, [this.name], false);
      document.addEventListener('keydown', this.handleKeyDown);
    }
    if (!this.modalPluginInstalled) {
      // eslint-disable-next-line no-console
      console.warn('ModalWindow component requires ModalPlugin. Please, activate ModalWindow plugin.');
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
};
</script>
