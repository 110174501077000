<template>
  <div class="calculator-header-stepper">
    <div class="progress-line"></div>
    <div
      class="calculator-header-step"
      v-for="(step, i) in steps"
      :class="{'-current': current === i}"
      :key="i"
    >
      <div class="number-wrap">
        <div class="number">
          {{ i+1 }}
        </div>
      </div>
      <div class="name">
        {{ step }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalculatorHeaderStepper',
  props: {
    steps: {
      type: Array,
    },
    current: {

    },
  },
};
</script>
