const promos = [
  {
    type: 'BASE',
    id: 'VIANOCE_2022',
    conditions: {
      start: new Date('2022-12-01'),
      end: new Date('2023-01-02'),
    },
    display: {
      text: 'Vypočítajte si cenu PZP, nechajte nám kontakt a ste v hre o 200 € darčekový poukaz NAY.',
      link: 'https://www.generali.sk/wp-content/uploads/2022/11/statut_vyhraj_200E_na_vianocne_nakupy.pdf',
      linkLabel: 'Zobraziť štatút',
      visual: require('@/assets/promo/promo-2022-12.png'),
    },
  },

  {
    type: 'CODE',
    id: 'VUB2022A',
    conditions: {
      code: 'VUB2022A',
      start: new Date('2022-06-01'),
      end: new Date('2022-09-01'),
    },
    display: {
      text: 'Stačí si vypočítať cenu poistenia a&nbsp;ste automaticky zaradený do súťaže 3x 100 € na tankovanie. Súťaž trvá do 31. 8. 2022 a výhercov zverejníme 7. 9. 2022 na&nbsp;stránke Generali.',
      link: 'https://www.generali.sk/wp-content/uploads/2022/06/sutazny-poriadok-3x100eur.pdf',
      linkLabel: 'Zobraziť štatút',
      visual: require('@/assets/promo/pn2k-3x100-eur.jpg'),
    },
    overrides: {
      ci360: {
        hideObtainerId: true,
      },
      appendSourceName: true,
    },
  },
  {
    type: 'CODE',
    id: 'VUB2022B',
    conditions: {
      code: 'VUB2022B',
      start: new Date('2022-06-01'),
      end: new Date('2022-09-01'),
    },
    display: {
      text: 'Stačí si vypočítať cenu poistenia a&nbsp;ste automaticky zaradený do súťaže 3x 100 € na tankovanie. Súťaž trvá do 31. 8. 2022 a výhercov zverejníme 7. 9. 2022 na&nbsp;stránke Generali.',
      link: 'https://www.generali.sk/wp-content/uploads/2022/06/sutazny-poriadok-3x100eur.pdf',
      linkLabel: 'Zobraziť štatút',
      visual: require('@/assets/promo/pn2k-3x100-eur.jpg'),
    },
    overrides: {
      obtainerId: '80000001-3',
      ci360: {
        hideObtainerId: true,
      },
      appendSourceName: true,
    },
  },
];

export default promos;
