<template>
  <BaseSpinner
    v-if="isLoading"
    :size="size === 'large' ? 40 : 16"
    :style="{ margin: '0 auto'}"
  />
  <div
    v-else
    class="calculator-header-price"
    :class="cssClasses"
  >
    <div class="frequency">
      {{ frequencyFormatted }}
    </div>
    <div class="amount">
      {{ amountFormatted }}
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@gds/components/BaseSpinner';

export default {
  components: {
    BaseSpinner,
  },
  props: {
    frequency: Number,
    amount: Number,
    isLoading: Boolean,
    size: {
      type: String,
      default: 'large',
      validator: (value) => ['large', 'small'].includes(value),
    },
  },
  computed: {
    cssClasses() {
      return [
        `-size-${this.size}`,
      ];
    },
    frequencyFormatted() {
      switch (this.frequency) {
        case 1: return 'Ročné poistné';
        case 2: return 'Polročné poistné';
        case 4: return 'Štvrťročné poistné';
        case 12: return 'Mesačné poistné';
        default: return 'Poistné';
      }
    },
    amountFormatted() {
      const amount = this.amount.toLocaleString('sk-SK', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return `${amount} €`;
    },
  },
};
</script>
