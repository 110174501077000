import Vue from 'vue';
import VueRouter from 'vue-router';
import scrollBehavior from '@gds/common/calculators/scrollBehavior';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/0',
    alias: '/',
    name: 'Step0',
    meta: {
      step: 0,
    },
    component: () => import('@/views/Step0.vue'),
  },
  {
    path: '/1',
    name: 'Step1',
    meta: {
      step: 1,
    },
    component: () => import('@/views/Step1.vue'),
  },
  {
    path: '/2',
    name: 'Step3',
    meta: {
      step: 2,
      continueButton: 'Zobraziť cenu',
    },
    component: () => import('@/views/Step3.vue'),
  },
  {
    path: '/3',
    name: 'Step5',
    meta: {
      step: 3,
    },
    component: () => import('@/views/Step5.vue'),
  },
  {
    path: '/4',
    name: 'Step7',
    meta: {
      step: 4,
    },
    component: () => import('@/views/Step7.vue'),
  },
  {
    path: '/5',
    name: 'Step9',
    meta: {
      step: 5,
      continueButton: 'Zobraziť zhrnutie',
    },
    component: () => import('@/views/Step9.vue'),
  },
  {
    path: '/6',
    name: 'Step10',
    meta: {
      step: 6,
      continueButton: 'Objednať poistenie',
    },
    component: () => import('@/views/Step10.vue'),
  },
  {
    path: '/7',
    name: 'Payment',
    meta: {
      step: 7,
      continueButton: 'Zaplatiť',
    },
    component: () => import('@/views/Payment.vue'),
  },
  {
    path: '/autorizacia',
    name: 'AuthorizePrefilledOffer',
    meta: {},
    component: () => import('@/views/AuthorizePrefilledOffer.vue'),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // Disable manual route change
  if (to.meta.step > store.state.progress.stepReached && process.env.NODE_ENV === 'production') {
    console.error('You are not allowed to enter this step!');
    next(from.path);
  } else {
    next();
  }
});

export default router;
