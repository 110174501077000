<template>
  <div>
    <h2 class="title">Nechajte nám vaše číslo a&nbsp;my vám zavoláme</h2>
    <form @submit.prevent="submit" v-if="!isDone">
      <FormGroup
        label="Telefón"
        :style="{ marginBottom: '10px' }"
      >
        <InputPhone
          v-model="phone"
        />
      </FormGroup>
      <BaseParagraph class="legal-text">
        Odoslaním formulára dôjde k spracúvaniu vašich osobných údajov pre účely uzatvorenia zmluvy. Viac info nájdete v našej <BaseLink href="https://www.generali.sk/o-nas/informacie-o-spracuvani-osobnych-udajov/" target="_blank">Informácii o spracúvaní osobných údajov</BaseLink>
      </BaseParagraph>
      <InfoBox
        v-if="isInvalid"
        variant="danger"
        size="small"
      >
        Zadajte, prosím, platný formát telefónneho čísla (9** *** ***).
      </InfoBox>
      <BaseButton
        block
        type="submit"
      >
        Zavolajte mi
      </BaseButton>
    </form>
    <InfoBox
      v-else
      variant="success"
      :style="{ marginBottom: 0 }"
    >
      Ďakujeme, budeme vás čo najskôr kontaktovať
    </InfoBox>
  </div>
</template>

<script>
import FormGroup from '@gds/components/FormGroup';
import InputPhone from '@gds/components/InputPhone';
import BaseButton from '@gds/components/BaseButton';
import BaseParagraph from '@gds/components/BaseParagraph';
import BaseLink from '@gds/components/BaseLink';
import InfoBox from '@gds/components/InfoBox';

export default {
  components: {
    FormGroup,
    InputPhone,
    BaseButton,
    InfoBox,
    BaseParagraph,
    BaseLink,
  },
  props: {
    callbackProps: Object,
  },
  data() {
    return {
      phone: { ...this.callbackProps.customerPhone },
      isInvalid: false,
      isDone: false,
    };
  },
  methods: {
    async submit() {
      if (this.validate()) {
        this.isInvalid = false;
        await this.sendToAPI();
        this.isDone = true;
      } else {
        this.isInvalid = true;
      }
      return true;
    },
    validate() {
      const prefixPassed = /^[+][\d]{1,3}$/.test(this.phone.prefix);
      const numberPassed = /^([\d]\s*){9}$/.test(this.phone.number);
      return prefixPassed && numberPassed;
    },
    async sendToAPI() {
      this.$sendToCI('CALLBACK');
      const data = {
        phoneNumber: this.phone.prefix + this.phone.number,
        product: this.callbackProps.product,
        continuationLink: this.callbackProps.continuationLink,
      };

      await fetch(this.callbackProps.endpoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@gds/styles/global.scss";

.legal-text {
  font-size: $font-size-100;
  color: $gray-500;
  margin-bottom: 20px;
}
</style>
