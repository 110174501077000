import Vue from 'vue';
import Vuex from 'vuex';
import { formatISO, parseISO } from 'date-fns';

import { createParty } from '@gds/common/calculators/fns/factories';
import { getPartyFullName, getPartyReadableDetails } from '@gds/common/calculators/fns/readable';
import { processMarketingTracking, generateContinuationLink } from '@gds/common/calculators/fns/misc';
import getBirthDateFromNationalIdentificationNumber from '@gds/common/calculators/InputParty/util/getBirthDateFromNationalIdentificationNumber';

import { transformSides } from '@/util/storeHelpers';
import getActivePromo from '@/util/getActivePromo';

import CREATE_CALCULATION from '@/graphql/CreateCalculation.gql';
import UPDATE_CALCULATION from '@/graphql/UpdateCalculation.gql';
import RATE_CALCULATION from '@/graphql/RateCalculation.gql';
import SAVE_CALCULATION from '@/graphql/SaveCalculation.gql';
import LOAD_CALCULATION from '@/graphql/LoadCalculation.gql';
import GET_CUSTOMER_DETAILS from '@/graphql/GetCustomerDetails.gql';
import LOAD_PREFILLED_OFFER_GREETING from '@/graphql/PrefilledOffer/LoadPrefilledOfferGreeting.gql';
import LOAD_PREFILLED_OFFER from '@/graphql/PrefilledOffer/LoadPrefilledOffer.gql';

Vue.use(Vuex);

const createVehicle = () => ({
  commercial: false,
  leased: null, // true, false
  usageType: null, // STANDARD, NONSTANDARD

  licensePlate: '',
  make: '',
  makeMap: {},
  model: '',
  modelMap: {},
  engineDisplacement: '',
  enginePower: '',
  yearOfManufacture: '',
  weight: '',
  seatCount: '',
  registrationNumber: '',
  vin: '',
  color: '',

  fuelType: '',
});

const store = new Vuex.Store({
  state: {
    calculation: {
      id: null,
      hash: null,
      isContinued: false,
      obtainerId: null,
      customerUid: null,
      productProps: {
        startDate: new Date(),
        frequency: 1,
        holderCausedDamage: null,
        package: 'M',
        specialOffer: '',
        claimsCheck: null,
      },
      vehicle: createVehicle(),
      customer: {
        ...createParty(),
        isVehicleHolder: true,
        isVehicleOwner: true,
      },
      owner: createParty(),
      agreements: {
        marketingAgreement: false,
        docs: false,
        customerStatement: false,
        electronicCorrespondence: true,
      },
      idd: {
        duplicateInsurance: null,
        collisionInsurance: null,
      },
      prices: {
        isLoading: false,
        hasLoadError: false,
        matrix: [],
      },
      final: {
        errors: [],
        price: '',
        policyNumber: '',
      },
    },
    progress: {
      stepReached: 0,
      returnTo: null,
    },
    activePromo: null,
    prefilledOffer: {
      isActive: false,
      isSuccess: false,
      wasFastForwarded: false,
      id: null,
      key: null,
    },
    miscFlags: {
      isBackendConnected: true,
      isCallbackSent: false,
    },
    marketingTrackingActionsSent: [],
  },
  getters: {
    productProps: (state) => state.calculation.productProps,
    vehicle: (state) => state.calculation.vehicle,
    customer: (state) => state.calculation.customer,
    owner: (state) => (state.calculation.customer.isVehicleOwner ? state.calculation.customer : state.calculation.owner),
    agreements: (state) => state.calculation.agreements,
    idd: (state) => state.calculation.idd,
    prices: (state) => state.calculation.prices.matrix,

    startDateISO: (state) => formatISO(state.calculation.productProps.startDate),
    activePromo: (state) => state.activePromo,

    customerFullName: (state, getters) => getPartyFullName(getters.customer),
    ownerFullName: (state, getters) => getPartyFullName(getters.owner),
    customerReadableDetails: (state, getters) => getPartyReadableDetails(getters.customer),
    ownerReadableDetails: (state, getters) => getPartyReadableDetails(getters.owner),

    vehicleReadableMake: (state) => {
      const { vehicle } = state.calculation;
      return vehicle.makeMap[vehicle.make];
    },
    vehicleReadableModel: (state) => {
      const { vehicle } = state.calculation;
      return vehicle.modelMap[vehicle.model];
    },
    vehicleReadableMakeModel: (state, getters) => `${getters.vehicleReadableMake} ${getters.vehicleReadableModel}`,
    vehicleReadableDetails: (state) => {
      const { vehicle } = state.calculation;
      const details = [];
      if (vehicle.licensePlate) details.push(`EČV: ${vehicle.licensePlate}`);
      if (vehicle.registrationNumber) details.push(`Číslo technického preukazu: ${vehicle.registrationNumber}`);
      if (vehicle.yearOfManufacture) details.push(`Rok výroby: ${vehicle.yearOfManufacture}`);
      if (vehicle.engineDisplacement) details.push(`Zdvihový objem motora: ${vehicle.engineDisplacement} cm³`);
      if (vehicle.enginePower) details.push(`Výkon motora: ${vehicle.enginePower} kw`);
      if (vehicle.weight) details.push(`Hmotnosť: ${vehicle.weight} kg`);
      if (vehicle.seatCount) details.push(`Počet miest na sedenie: ${vehicle.seatCount}`);
      if (vehicle.vin) details.push(`VIN: ${vehicle.vin}`);
      if (vehicle.color) details.push(`Farba: ${vehicle.color}`);

      return details.join('<br>');
    },
    currentPrice: (state) => {
      const { matrix } = state.calculation.prices;
      if (matrix.length) {
        // eslint-disable-next-line arrow-body-style
        const match = matrix.find((variant) => {
          return variant.frequency === state.calculation.productProps.frequency
            && variant.package === state.calculation.productProps.package;
        });
        if (match) {
          return match.prices.mainPrice;
        }
      }
      return 0;
    },
    priceIsLoading: (state) => state.calculation.prices.isLoading,
    priceHasLoadError: (state) => state.calculation.prices.hasLoadError,
    isPrefilledOfferFlow: (state) => state.prefilledOffer.isActive,
    shouldPrefilledOfferFastForward: (state) => state.prefilledOffer.isActive && !state.prefilledOffer.wasFastForwarded,

    continuationLink: (state) => {
      const { id, hash } = state.calculation;
      const base = process.env.VUE_APP_FRONTEND_BASE || 'http://viv.sk/generali/calculators/pzp/';
      return generateContinuationLink(base, id, hash);
    },

    getGraphVars: (state, getters) => {
      const {
        productProps, vehicle, customer, owner, agreements, idd, startDateISO,
      } = getters;

      const removeWhitespace = (value) => value.replace(/\s+/g, '');
      const birthDate = getBirthDateFromNationalIdentificationNumber(customer.nationalIdentificationNumber);
      const birthYear = birthDate ? birthDate.getFullYear() : null;

      const vars = {
        id: state.calculation.id,
        data: {
          continuationLink: getters.continuationLink,
          productProps: {
            frequency: Number(productProps.frequency),
            insuranceStart: startDateISO,
            holderCausedDamage: productProps.claimsCheck != null
              ? Boolean(productProps.claimsCheck)
              : Boolean(productProps.holderCausedDamage),
            package: productProps.package,
          },
          agreements,
          idd,

          vehicle: {
            leased: Boolean(vehicle.leased),
            commercial: Boolean(vehicle.commercial),
            usageType: String(vehicle.usageType),

            licensePlate: String(vehicle.licensePlate),
            make: String(vehicle.make),
            model: String(vehicle.model),
            engineDisplacement: Number(vehicle.engineDisplacement),
            enginePower: Number(vehicle.enginePower),
            yearOfManufacture: Number(vehicle.yearOfManufacture),
            weight: Number(vehicle.weight),
            seatCount: Number(vehicle.seatCount),
            registrationNumber: String(vehicle.registrationNumber),
            vin: String(vehicle.vin),
            color: String(vehicle.color),

            holderPostalCode: removeWhitespace(customer.address.postalCode),
            holderType: String(customer.type),
            holderBirthYear: birthYear,
          },

          sides: transformSides(customer, owner),

        },
      };
      if (customer.type !== 'PERSON') {
        vars.data.vehicle.holderIdentification = customer.companyRegistrationNumber;
      }
      return vars;
    },

  },
  mutations: {},
  actions: {
    async create({ state }, apollo) {
      const result = await apollo.mutate({
        mutation: CREATE_CALCULATION,
        variables: {
          product: 'PZP17',
        },
      });
      if (result.data.createCalculation) {
        const { createCalculation } = result.data;
        state.calculation.id = createCalculation.id;
        state.calculation.hash = createCalculation.hash;
      }
      return true;
    },
    async update({ state, getters, dispatch }, { apollo, withPrices }) {
      const token = await this._vm.$recaptcha('update');
      if (withPrices) {
        state.calculation.prices.isLoading = true;
        state.calculation.prices.hasLoadError = false;
        state.calculation.prices.matrix = [];
      }
      try {
        const updateResult = await apollo.mutate({
          mutation: UPDATE_CALCULATION,
          variables: {
            token,
            ...getters.getGraphVars,
          },
        });
        if (updateResult.data.updatePzp17Calculation) {
          const { claimsCheck } = updateResult.data.updatePzp17Calculation.calculation.productProps;
          const newClaimsCheck = state.calculation.productProps.claimsCheck == null && claimsCheck != null;
          if (newClaimsCheck) {
            state.calculation.productProps.claimsCheck = claimsCheck;
            const discrepancy = state.calculation.productProps.holderCausedDamage !== claimsCheck;
            if (discrepancy) {
              dispatch('update', {
                apollo,
                withPrices: true,
              });
            }
          }
        }

        // TODO: Fetch M price first, only then other prices
        if (updateResult.data.updatePzp17Calculation && withPrices) {
          const rateResult = await apollo.query({
            query: RATE_CALCULATION,
            fetchPolicy: 'no-cache',
            variables: {
              id: state.calculation.id,
              packages: ['M', 'L', 'XL'],
              frequencies: [state.calculation.productProps.frequency],
            },
          });
          if (rateResult.data.pricingMatrix) {
            state.calculation.prices.matrix = rateResult.data.pricingMatrix;
            state.calculation.prices.isLoading = false;
          }
        }
        return true;
      } catch (e) {
        state.calculation.prices.isLoading = false;
        if (e.message === 'GraphQL error: ratePolicy failed to return the price (error 434248)') {
          state.calculation.prices.matrix = [];
          state.calculation.prices.hasLoadError = true;
        }
        if (e.message === 'GraphQL error: too many requests') {
          state.miscFlags.isBackendConnected = false;
          this._vm.$modal.show('globalRecaptcha');
        }
      }
      return false;
    },
    async setObtainer({ state }, { apollo, obtainerId }) {
      state.calculation.obtainerId = obtainerId;
      const token = await this._vm.$recaptcha('update');
      const result = await apollo.mutate({
        mutation: UPDATE_CALCULATION,
        variables: {
          id: state.calculation.id,
          token,
          data: {
            administratingObtainer: obtainerId,
          },
        },
      });
      if (result) {
        return true;
      }
      return false;
    },
    async save({ state }, apollo) {
      try {
        const result = await apollo.mutate({
          mutation: SAVE_CALCULATION,
          variables: {
            id: state.calculation.id,
          },
        });
        if (result.data.savePzp17Calculation) {
          const { savePzp17Calculation } = result.data;
          state.calculation.final.errors = savePzp17Calculation.errors;
          state.calculation.final.policyNumber = savePzp17Calculation.policyNumber;
          state.calculation.final.price = savePzp17Calculation.finalPrices?.mainPrice;
          if (state.calculation.final.policyNumber) {
            return true;
          }
          return false;
        }
        state.calculation.final.errors = result.data;
        return false;
      } catch (error) {
        state.calculation.final.errors = error;
        return false;
      }
    },
    async loadCustomer({ state }, apollo) {
      const result = await apollo.mutate({
        mutation: GET_CUSTOMER_DETAILS,
        variables: {
          useKz: true,
          uid: state.calculation.customerUid,
        },
      });

      if (result.data.operatorData) {
        // Customer
        const customerParty = result.data.operatorData;

        state.calculation.customer.type = customerParty.sideType;
        state.calculation.customer.firstName = customerParty.firstName;
        state.calculation.customer.lastName = customerParty.lastName;
        state.calculation.customer.businessName = customerParty.businessName;
        state.calculation.customer.titleBefore = customerParty.titleBefore;
        state.calculation.customer.nationality = customerParty.nationality;
        if (customerParty.identificationType === 'BIRTHNUMBER') {
          state.calculation.customer.nationalIdentificationNumber = customerParty.identification;
        }
        if (customerParty.identificationType === 'BUSINESSID') {
          state.calculation.customer.companyRegistrationNumber = customerParty.identification;
        }
        state.calculation.customer.email = customerParty.residenceAddress.email;
        if (customerParty.residenceAddress.phonePrefix) {
          state.calculation.customer.phone.prefix = customerParty.residenceAddress.phonePrefix;
        }
        state.calculation.customer.phone.number = customerParty.residenceAddress.phone;

        // Address
        const customerAddress = customerParty.residenceAddress;
        state.calculation.customer.address.postalCode = customerAddress.postalCode;
        state.calculation.customer.address.town = customerAddress.town;
        state.calculation.customer.address.street = customerAddress.street;
        state.calculation.customer.address.streetNumber = customerAddress.buildingNumber;
      }
    },
    async loadCalculation({ state }, apollo) {
      const token = await this._vm.$recaptcha('update');
      const result = await apollo.mutate({
        mutation: LOAD_CALCULATION,
        variables: {
          id: state.calculation.id,
          token,
          data: {},
        },
      });
      if (result.data) {
        const {
          vehicle, sides, productProps, idd, agreements,
        } = result.data.updatePzp17Calculation.calculation;

        // ProductProps
        state.calculation.productProps.startDate = parseISO(productProps.insuranceStart);
        state.calculation.productProps.holderCausedDamage = productProps.holderCausedDamage;
        state.calculation.productProps.frequency = productProps.frequency;
        state.calculation.productProps.package = productProps.package;

        // Vehicle
        state.calculation.vehicle = {
          ...vehicle,
        };
        state.calculation.vehicle.makeMap = { [vehicle.make]: '…' };
        state.calculation.vehicle.modelMap = { [vehicle.model]: '…' };

        // Customer
        const customerParty = sides[0];

        state.calculation.customer.birthYear = String(vehicle.holderBirthYear);
        state.calculation.customer.type = customerParty.sideType;
        state.calculation.customer.firstName = customerParty.firstName;
        state.calculation.customer.lastName = customerParty.lastName;
        state.calculation.customer.businessName = customerParty.businessName;
        state.calculation.customer.titleBefore = customerParty.titleBefore;
        state.calculation.customer.nationality = customerParty.nationality;
        if (customerParty.identificationType === 'BIRTHNUMBER') {
          state.calculation.customer.nationalIdentificationNumber = customerParty.identification;
        }
        if (customerParty.identificationType === 'BUSINESSID') {
          state.calculation.customer.companyRegistrationNumber = customerParty.identification;
        }
        state.calculation.customer.email = customerParty.residenceAddress.email;
        state.calculation.customer.phone.prefix = customerParty.residenceAddress.phonePrefix;
        state.calculation.customer.phone.number = customerParty.residenceAddress.phone;

        // Address
        const customerAddress = customerParty.residenceAddress;
        state.calculation.customer.address.postalCode = customerAddress.postalCode;
        state.calculation.customer.address.town = customerAddress.town;
        state.calculation.customer.address.street = customerAddress.street;
        state.calculation.customer.address.streetNumber = customerAddress.buildingNumber;

        // Documents
        const customerDocument = customerParty.documents[0];
        if (customerDocument) {
          state.calculation.customer.documentType = customerDocument.documentType;
          if (document.documentType === 'ID') {
            state.calculation.customer.documentNumber = `${customerDocument.serie}${customerDocument.number}`;
          } else {
            state.calculation.customer.documentNumber = document.number;
          }
        }

        // IDD
        state.calculation.idd.duplicateInsurance = idd.duplicateInsurance;
        state.calculation.idd.collisionInsurance = idd.collisionInsurance;

        // Agreements
        state.calculation.agreements.marketingAgreement = agreements.marketingAgreement;
        state.calculation.agreements.docs = agreements.docs;
        state.calculation.agreements.customerStatement = agreements.customerStatement;
        state.calculation.agreements.electronicCorrespondence = agreements.electronicCorrespondence;

        return true;
      }
      return false;
    },
    setCalculationSensibleDefaults({ state }) {
      state.calculation.vehicle.commercial = false;
      state.calculation.vehicle.leased = false;
      state.calculation.vehicle.usageType = 'STANDARD';
      state.calculation.productProps.holderCausedDamage = false;
      state.calculation.customer.type = 'PERSON';
      state.calculation.agreements.marketingAgreement = true;
    },
    async loadPrefilledOfferGreeting({ state }, apollo) {
      const result = await apollo.query({
        query: LOAD_PREFILLED_OFFER_GREETING,
        variables: {
          id: state.prefilledOffer.id,
        },
      });
      if (result.data.prefilledOffer) {
        const details = result.data.prefilledOffer;
        state.calculation.customer.firstName = details.firstName;
        state.calculation.customer.lastName = details.lastName;

        const fullGreeting = `${details.greeting} ${details.firstName}`;
        state.activePromo = getActivePromo(details.campaignId);
        state.activePromo.display.text = `<strong>${fullGreeting},</strong><br>
        pozrite si akú cenu poistenie auta pre vás máme. Teraz navyše môžete vyhrať palivovú kartu. Stačí si vypočítať cenu poistenia a&nbsp;ste automaticky zaradený do súťaže 3x 100 € na tankovanie. Súťaž trvá do 31. 8. 2022 a výhercov zverejníme 7. 9. 2022 na&nbsp;stránke Generali.`;
        return true;
      }
      return false;
    },
    async loadPrefilledOffer({ state, dispatch }, apollo) {
      try {
        const result = await apollo.query({
          query: LOAD_PREFILLED_OFFER,
          variables: {
            id: state.prefilledOffer.id,
            key: state.prefilledOffer.key,
          },
        });
        if (result.data.prefilledOffer) {
          const details = result.data.prefilledOffer;

          // Defaults
          state.prefilledOffer.isSuccess = true;
          dispatch('setCalculationSensibleDefaults');

          // Loaded
          state.calculation.productProps.holderCausedDamage = details.holderCausedDamage;

          state.calculation.customer.nationalIdentificationNumber = details.nationalIdentificationNumber;
          state.calculation.customer.birthYear = details.birthYear;
          state.calculation.customer.firstName = details.firstName;
          state.calculation.customer.lastName = details.lastName;
          state.calculation.customer.titleBeforeName = details.titleBefore;

          state.calculation.customer.email = details.email;
          state.calculation.customer.phone.prefix = details.phonePrefix;
          state.calculation.customer.phone.number = details.phone;
          state.calculation.customer.address.postalCode = details.address.postalCode;
          state.calculation.customer.address.town = details.address.town;
          state.calculation.customer.address.street = details.address.street;
          state.calculation.customer.address.streetNumber = details.address.buildingNumber;

          // TEMPORARY
          state.calculation.customer.documentType = details.document.documentType;
          state.calculation.customer.documentNumber = details.document.number;

          // Set obtainer ID
          const { obtainerId } = details;
          dispatch('setObtainer', {
            apollo,
            obtainerId,
          });
        }
        return true;
      } catch {
        return false;
      }
    },
    sendMarketingTracking({ state }, action) {
      processMarketingTracking(state, {
        product: 'pzp',
        action,
        price: state.calculation.final.price,
      });
    },
  },
});

export default store;
