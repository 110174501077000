const transformDocuments = (party) => {
  const documentsArray = [];
  const { documentType, documentNumber } = party;
  if (documentType === 'ID') {
    const cleanSerie = documentNumber.substring(0, 2);
    const cleanNumber = documentNumber.substring(2, 8);
    documentsArray.push({
      documentType: party.documentType,
      serie: cleanSerie,
      number: cleanNumber,
    });
  } else {
    documentsArray.push({
      documentType: party.documentType,
      number: party.documentNumber,
    });
  }
  return documentsArray;
};

const transformAddress = (address, includeVirtualContacts = false, side) => {
  const result = {
    postalCode: address.postalCode.replace(/\s+/, ''),
    town: address.town,
    street: address.street,
    buildingNumber: address.streetNumber,
  };
  if (includeVirtualContacts) {
    result.email = side.email;
    result.phonePrefix = side.phone.prefix;
    result.phone = side.phone.number;
  }
  return result;
};

export {
  transformDocuments,
  transformAddress,
};
