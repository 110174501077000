<template>
  <button
    class="icon-button"
    type="button"
    @click="$emit('click', $event)"
  >
    <FontAwesomeIcon
      class="icon-button-icon"
      :icon="icon"
    />
    <div class="icon-button-content">
      <h3 class="icon-button-title">{{ title }}</h3>
    </div>
  </button>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'IconButton',
  components: {
    FontAwesomeIcon,
  },
  props: {
    icon: Object,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@gds/styles/global.scss";

.icon-button {
  @include reset-button;
  text-align: left;
  background-color: $gray-200;
  padding: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  line-height: $line-height;
  & + & {
    margin-top: 20px;
  }
  .icon-button-icon {
    color: $primary;
    font-size: 2rem;
    margin: 0 1.5rem 0 0.5rem;
  }
  .icon-button-title {
    margin: 0;
    font-size: $font-size-300;
  }
  &:hover {
    background-color: $gray-300;
  }
}
</style>
