<template>
  <div
    class="input-select-custom"
    @keydown="handleKeyDown"
  >
    <BaseButton
      class="toggle"
      variant="inputlike"
      block
    >
      {{ selectLabel }}
    </BaseButton>
    <div
      v-if="isOpen"
      class="dropdown-window"
    >
      <div class="dropdown-header">
        <InputText
          ref="query"
          v-model="query"
        />
        <FontAwesomeIcon
          :icon="iconSearch"
          class="dropdown-header-icon"
        />
      </div>
      <div class="dropdown-options">
        <BaseButton
          v-for="(option, optionIndex) in optionsFiltered"
          :key="optionIndex"
          class="dropdown-option"
          :class="{ '-detached': option.detached }"
          variant="inputlike"
          block
          @click="selectOption(option)"
        >
          {{ option.label }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import './InputSelect.scss';
import BaseButton from '@gds/components/BaseButton';
import InputText from '@gds/components/InputText';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';

const normalizeString = (query) => {
  if (query !== null) {
    return query.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  return '';
};

export default {
  name: 'InputSelectCustom',
  components: {
    BaseButton,
    InputText,
    FontAwesomeIcon,
  },
  data() {
    return {
      isOpen: false,
      query: '',
      iconSearch: faSearch,
    };
  },
  props: {
    value: {
      type: [String, Number, Boolean],
    },
    options: {
      type: Array,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
  },
  computed: {
    optionsFiltered() {
      return this.options.filter(
        (option) => (
          !option.disabled
          && option.value !== null
          && normalizeString(option.label).includes(normalizeString(this.query))
        ),
      );
    },
    selectLabel() {
      const match = this.options.find((option) => option.value === this.value);
      return match
        ? match.label
        : 'Prosím, vyberte';
    },
  },
  methods: {
    openDropdown() {
      this.isOpen = true;
      this.$nextTick(() => {
        this.$refs.query.$el.focus();
      });
    },
    closeDropdown() {
      this.isOpen = false;
    },
    selectOption(option) {
      this.$emit('input', option.value);
      this.$emit('change', option.value);
      this.closeDropdown();
    },
    handleComponentFocus() {
      this.openDropdown();
    },
    handleDocumentFocus(event) {
      if (this.$el.contains(event.target)) {
        this.openDropdown();
        return;
      }
      this.closeDropdown();
    },
    handleKeyDown(event) {
      const { key } = event;

      switch (key) {
        case 'Esc': // IE/Edge
        case 'Escape': {
          this.closeDropdown();
          break;
        }
        default:
      }
    },

  },
  mounted() {
    this.$el.addEventListener('focus', this.handleComponentFocus);
    document.addEventListener('click', this.handleDocumentFocus);
  },
  beforeDestroy() {
    this.$el.removeEventListener('focus', this.handleComponentFocus);
    document.removeEventListener('click', this.handleDocumentFocus);
  },

};
</script>
