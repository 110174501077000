<template>
  <div class="calculator-step">
    <CalculatorHeader
      :variant="headerVariant"
      :price="price"
      :progress="progress"
      :title="title"
      :titleLink="titleLink"
      :logoLink="logoLink"
    />
    <div class="main">
      <div class="content">
        <slot/>
      </div>
      <div
        class="actions"
        v-if="hasContinueButton"
      >
        <CalculatorStepBackButton
          v-if="hasBackButton"
          @click="$emit('click-back')"
        />
        <BaseButton
          block
          @click="$emit('click-proceed')"
        >
          {{ continueButtonText }}
        </BaseButton>
      </div>
    </div>
    <CalculatorStepFooter
      :footer="footer"
    />
  </div>
</template>

<script>
import './CalculatorStep.scss';
import CalculatorHeader from '@gds/components/CalculatorHeader';
import BaseButton from '@gds/components/BaseButton';
import CalculatorStepBackButton from './components/CalculatorStepBackButton.vue';
import CalculatorStepFooter from './components/CalculatorStepFooter.vue';

export default {
  name: 'CalculatorStep',
  components: {
    CalculatorHeader,
    BaseButton,
    CalculatorStepBackButton,
    CalculatorStepFooter,
  },
  props: {
    headerVariant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'vubCobrand'].includes(value),
    },
    title: {
      type: String,
      default: 'Kalkulačka',
    },
    titleLink: {
      type: String,
    },
    logoLink: {
      type: String,
      default: 'https://www.generali.sk',
    },
    progress: {
      type: Object,
      default() { return null; },
    },
    price: {
      type: Object,
    },
    hasBackButton: {
      type: Boolean,
      default: false,
    },
    hasContinueButton: {
      type: Boolean,
      default: true,
    },
    continueButtonText: {
      type: String,
      default: 'Pokračovať',
    },
    footer: {
      type: Object,
      default() {
        const year = new Date().getFullYear();
        return {
          copyright: `© ${year} Generali Poisťovňa, pobočka poisťovne z iného členského štátu`,
          links: [
            { label: 'Údaje o nás', link: 'https://www.generali.sk/o-nas/' },
            { label: 'Ochrana osobných údajov', link: 'https://www.generali.sk/o-nas/informacie-o-spracuvani-osobnych-udajov/' },
            { label: 'Súbory cookies', link: 'https://www.generali.sk/o-nas/cookies/' },
          ],
        };
      },
    },
  },
};
</script>
