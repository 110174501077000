const loadCalculation = async (vm, options) => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const hash = urlParams.get('hash');
  const obtainerId = urlParams.get('ziskId');

  if (id && hash) {
    // eslint-disable-next-line no-param-reassign
    vm.$store.state.calculation.id = id;
    // eslint-disable-next-line no-param-reassign
    vm.$store.state.calculation.hash = hash;
    // eslint-disable-next-line no-param-reassign
    vm.$store.state.calculation.isContinued = true;
    const loadResult = await vm.$store.dispatch('loadCalculation', vm.$apollo);
    if (loadResult) {
      if (obtainerId) {
        vm.$store.dispatch('setObtainer', {
          apollo: vm.$apollo,
          obtainerId,
        });
      }
      // eslint-disable-next-line no-param-reassign
      vm.$store.state.progress.stepReached = options.setStepReached;
      vm.$router.push(options.pushStep);
    }
  }
};

const getRestUid = () => {
  const uidElement = document.querySelector('[name="Sirael::u3RestUid"]');
  if (uidElement) {
    return uidElement.content;
  }
  if (window.siraelConfig?.u3RestUid) {
    return window.siraelConfig?.u3RestUid;
  }
  return null;
};

const loadCustomerDetails = (store, apollo) => {
  const customerUid = getRestUid();
  if (customerUid) {
    // eslint-disable-next-line no-param-reassign
    store.state.calculation.customerUid = customerUid;
    store.dispatch('loadCustomer', apollo);
  }
};

const createNewCalculation = async (vm) => {
  const { $store, $apollo } = vm;
  const urlParams = new URLSearchParams(window.location.search);
  if (!$store.state.calculation.id || !$store.state.calculation.hash) {
    await $store.dispatch('create', $apollo);
    const obtainerId = urlParams.get('ziskId');
    if (obtainerId) {
      $store.dispatch('setObtainer', {
        apollo: $apollo,
        obtainerId,
      });
    }
  }
};

const tryProceed = (vm) => {
  const innerProceed = vm.$refs.stepContents.tryProceed;
  const hasInnerProceed = (typeof innerProceed === 'function');
  if (hasInnerProceed) {
    innerProceed();
  } else {
    vm.$v.validator.checkAndContinue(vm);
  }
};

export {
  loadCalculation,
  loadCustomerDetails,
  createNewCalculation,
  tryProceed,
};
