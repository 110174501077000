<template>
  <component
    :is="activeComponent"
    :value="value"
    :name="name"
    :options="options"
    :autocomplete="autocomplete"

    @change="$emit('input', $event); $emit('change', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import InputSelectNative from './InputSelectNative.vue';
import InputSelectCustom from './InputSelectCustom.vue';

export default {
  name: 'InputSelect',
  props: {
    value: {
      type: [String, Number, Boolean],
    },
    options: {
      type: Array,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    search: {
      type: Boolean,
    },
  },
  computed: {
    activeComponent() {
      return this.search
        ? InputSelectCustom
        : InputSelectNative;
    },
  },
};
</script>
