/*
  Usage:

  1. Install the plugin in main.js via Vue.use()
  2. Insert <ModalWindow> component with name prop
  3. Call $modal.show(name) or $modal.hide(name)
*/

/* eslint-disable no-param-reassign */

const ModalPlugin = {
  install(Vue) {
    Vue.prototype.$modal = new Vue({
      data() {
        return {
          state: {},
        };
      },
      methods: {
        show(name) {
          this.state[name] = true;
        },
        hide(name) {
          this.state[name] = false;
        },
        toggle(name) {
          this.state[name] = !this.state[name];
        },
      },
    });
  },
};

export default ModalPlugin;
