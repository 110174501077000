import { transformDocuments, transformAddress } from '@gds/common/calculators/fns/transforms';

const transformSides = (customer, owner) => {
  const sides = [];

  const createSide = (side) => {
    const result = {
      sideType: side.type,
      firstName: side.firstName,
      lastName: side.lastName,
      businessName: side.businessName,
      titleBefore: side.titleBeforeName,
      nationality: side.nationality,
    };
    if (side.type === 'PERSON') {
      result.identificationType = 'BIRTHNUMBER';
      // Remove / and whitespace
      result.identification = side.nationalIdentificationNumber.replace(/[/\s]+/g, '');
    } else {
      result.identificationType = 'BUSINESSID';
      result.identification = side.companyRegistrationNumber;
    }
    if (side.type !== 'COMPANY' && side.documentNumber) {
      result.documents = transformDocuments(side);
    }
    return result;
  };

  // Side init
  const customerSide = createSide(customer);

  // Addresses
  customerSide.residenceAddress = transformAddress(customer.address, true, customer);
  if (customer.correspondenceAddress.postalCode) {
    customerSide.correspondenceAddress = transformAddress(customer.correspondenceAddress);
  }

  // Set roles
  customerSide.sideRoles = ['CUSTOMER', 'HOLDER'];
  if (customer.isVehicleOwner === true) {
    customerSide.sideRoles.push('OWNER');
  }
  sides.push(customerSide);

  if (customer.isVehicleOwner === false) {
    const ownerSide = createSide(owner);

    ownerSide.residenceAddress = transformAddress(owner.address);
    if (customer.correspondenceAddress.postalCode) {
      ownerSide.correspondenceAddress = transformAddress(owner.correspondenceAddress);
    }

    ownerSide.residenceAddress = {
      postalCode: owner.address.postalCode,
      town: owner.address.town,
      street: owner.address.street,
      buildingNumber: owner.address.streetNumber,
    };
    if (owner.correspondenceAddress.postalCode) {
      ownerSide.correspondenceAddress = {
        postalCode: owner.correspondenceAddress.postalCode,
        town: owner.correspondenceAddress.town,
        street: owner.correspondenceAddress.street,
        buildingNumber: owner.correspondenceAddress.streetNumber,
      };
    }
    ownerSide.sideRoles = ['OWNER'];
    sides.push(ownerSide);
  }

  return sides;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  transformSides,
};
