<template>
  <Fragment>
    <header
      class="calculator-header-main"
      v-if="variant === 'default'"
    >
      <a
        :href="logoLink"
        class="logo-link"
      >
        <img
          src="./img/generali-logo.svg"
          alt="Generali"
          class="logo"
        >
      </a>
      <div class="main">
        <h1 class="title">
          <a
            :href="titleLink"
            class="title-link"
          >
            {{ title }}
          </a>
          <span
            class="calculator-header-debug-badge"
            v-if="debugMode"
          >
            debug
          </span>
        </h1>
      </div>
      <div class="price">
        <CalculatorHeaderPrice
          v-if="price"
          :amount="price.amount"
          :frequency="price.frequency"
          :isLoading="price.isLoading"
          size="large"
        />
      </div>
    </header>
    <header
      class="calculator-header-main-vub-cobrand"
      v-if="variant === 'vubCobrand'"
    >
      <div class="brands">
        <a
          :href="logoLink"
          class="logo-link"
        >
          <img
            src="./img/cobrand-logo-generali.svg"
            alt="Generali"
            class="logo"
          >
        </a>
        <a
          class="logo-link"
        >
          <img
            src="./img/cobrand-logo-vub.svg"
            alt="VÚB banka"
            class="logo"
          >
        </a>
      </div>
      <div class="main">
        <h1 class="title">
          <a
            :href="titleLink"
            class="title-link"
          >
            {{ title }}
          </a>
        </h1>
        <div class="price">
          <CalculatorHeaderPrice
            v-if="price"
            :amount="price.amount"
            :frequency="price.frequency"
            :isLoading="price.isLoading"
            size="small"
          />
        </div>
      </div>
    </header>
    <CalculatorHeaderStepper
      :steps="progress.steps"
      :current="progress.current"
    />
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

import './CalculatorHeader.scss';
import CalculatorHeaderPrice from './components/CalculatorHeaderPrice.vue';
import CalculatorHeaderStepper from './components/CalculatorHeaderStepper.vue';

export default {
  name: 'CalculatorHeader',
  components: {
    Fragment,
    CalculatorHeaderPrice,
    CalculatorHeaderStepper,
  },
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'vubCobrand'].includes(value),
    },
    title: {
      type: String,
    },
    titleLink: {
      type: String,
    },
    logoLink: {
      type: String,
    },
    progress: {
      type: Object,
    },
    price: {
      type: Object,
    },
  },
  computed: {
    debugMode() {
      return !!process.env.VUE_APP_DEBUG_MODE;
    },
  },
};
</script>
