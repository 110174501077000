const createParty = () => ({
  type: 'PERSON', // PERSON, COMPANY, SELFEMPLOYED
  birthYear: null,

  phone: { prefix: '+421', number: '' },
  email: '',

  firstName: '',
  lastName: '',
  titleBeforeName: '',
  businessName: '',
  nationalIdentificationNumber: '',
  companyRegistrationNumber: '',
  documentType: 'ID',
  documentNumber: '',
  nationality: 'SVK',

  address: {
    postalCode: '',
    town: '',
    street: '',
    streetNumber: '',
  },
  correspondenceAddress: {
    postalCode: '',
    town: '',
    street: '',
    streetNumber: '',
  },
});

export {
  // eslint-disable-next-line import/prefer-default-export
  createParty,
};
