<template>
  <div class="calculator-step-footer">
    <nav class="links">
      <ul class="linklist">
        <li
          v-for="(item, i) in footer.links"
          :key="i"
        >
          <a :href="item.link" target="_blank">
            {{ item.label }}
          </a>
        </li>
      </ul>
    </nav>
    <div class="copyright" v-text="footer.copyright"/>
  </div>
</template>

<script>
export default {
  name: 'CalculatorStepFooter',
  props: {
    footer: {
      type: Object,
    },
  },
};
</script>
