<template>
  <a
    :href="href"
    :target="target"
    :role="role"
    class="base-link"
    @click="$emit('click', $event)"
  >
    <slot></slot>
    <InterfaceIcon
      v-if="external"
      icon="external"
      size="xsmall"
    />
  </a>
</template>

<script>
import './BaseLink.scss';
import InterfaceIcon from '@gds/components/InterfaceIcon';

export default {
  name: 'BaseLink',
  components: {
    InterfaceIcon,
  },
  props: {
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    role: {
      type: String,
      default: 'link',
    },
    external: {
      type: Boolean,
    },
  },
};
</script>
