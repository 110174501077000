<template>
  <div class="help-widget">
    <button
      type="button"
      class="help-widget-button"
      @click="isExpanded = !isExpanded"
    >
      <FontAwesomeIcon
        :icon="icons.button"
      />
    </button>
    <transition name="fade">
      <div class="help-widget-canvas" v-if="isExpanded">
        <svg
          class="close"
          role="button"
          @click="close"
          width="14"
          height="14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1l12 12M1 13L13 1" stroke="#8F8F8F"/>
        </svg>
        <div v-if="screen === 'welcome'">
          <h2 class="title">Ako vám poradíme?</h2>
          <div class="stack">
            <IconButton
              :icon="icons.callback"
              title="Zavolajte mi"
              @click="openCallback"
            />
            <IconButton
              :icon="icons.chatbot"
              title="Online chat"
              @click="openChatbot"
            />
          </div>
        </div>

        <div v-else-if="screen === 'callback'">
          <HelpCallback
            :callbackProps="callbackProps"
          />
        </div>

        <div v-else-if="screen === 'chatbot'">
          <h2 class="title">Chatbot</h2>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone, faCommentDots } from '@fortawesome/pro-light-svg-icons';
import IconButton from './IconButton.vue';
import HelpCallback from './HelpCallback.vue';

// Intervals
let intervalId = null;
const intervalFrequency = 5; // Seconds
const callbackAutoOpenDelay = 120; // Seconds

export default {
  name: 'HelpWidget',
  components: {
    FontAwesomeIcon,
    IconButton,
    HelpCallback,
  },
  props: {
    callbackProps: Object,
  },
  data() {
    return {
      isExpanded: false,
      screen: 'callback',
      icons: {
        button: faPhone,
        callback: faPhone,
        chatbot: faCommentDots,
      },
      timeSinceLastAction: 0,
    };
  },
  methods: {
    close() {
      this.isExpanded = false;
      this.screen = 'callback';
    },
    updateTimeSinceLastAction() {
      this.timeSinceLastAction += intervalFrequency / 1000;
      if (this.timeSinceLastAction >= callbackAutoOpenDelay) {
        this.callbackAutoOpen();
      }
    },
    resetTimeSinceLastAction() {
      this.timeSinceLastAction = 0;
    },
    callbackAutoOpen() {
      clearInterval(intervalId);
      this.isExpanded = true;
      this.openCallback();
    },
    openCallback() {
      this.screen = 'callback';
    },
    openChatbot() {
      this.isExpanded = false;
      document.dispatchEvent(new CustomEvent('ew-toggle-window'));
    },
  },
  mounted() {
    intervalId = setInterval(this.updateTimeSinceLastAction, intervalFrequency * 1000);
    ['click', 'scroll'].forEach(
      (eventType) => document.addEventListener(eventType, this.resetTimeSinceLastAction),
    );
  },
  beforeDestroy() {
    clearInterval(intervalId);
  },
};
</script>

<style lang="scss" scoped>
@import "@gds/styles/global.scss";

$button-size: 48px;
$button-size-lg: 72px;
$canvas-pad: 15px;
$canvas-arrow-size: 32px;
$button-pad-x: 12px;
$button-pad-y: 12px;

.help-widget-button {
  position: fixed;
  z-index: 100;
  right: $button-pad-x;
  bottom: $button-pad-y;

  @include reset-button;
  background-color: $red-10;
  border: 2px solid $white;
  color: $white;
  width: $button-size;
  height: $button-size;
  border-radius: 50%;
  font-size: $button-size * 0.42;
  @media screen and (min-width: 768px) {
    width: $button-size-lg;
    height: $button-size-lg;
    font-size: $button-size-lg * 0.42;
  }

}
.help-widget-canvas {
  position: fixed;
  z-index: 100;

  right: $canvas-pad;
  width: min(345px, calc(100vw - 30px));
  bottom: $button-pad-y + $button-size + ($canvas-arrow-size * 0.75);
  background-color: $white;
  padding: $container-pad-x;
  box-shadow: 0 3px 20px rgba(black, 0.1);
  transform-origin: bottom right;
  @media screen and (min-width: 768px) {
    bottom: $button-pad-y + $button-size-lg + ($canvas-arrow-size * 0.75);
  }

  .close {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
  }
  // Arrow
  &::before {
    $size: $canvas-arrow-size;
    $border: $size * 0.5;
    content: "";
    display: block;
    position: absolute;
    right: ($button-size - $size) * 0.5;
    top: 100%;
    border: $border solid transparent;
    border-top-color: $white;
    @media screen and (min-width: 768px) {
      right: ($button-size-lg - $size) * 0.5;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease;
}

.fade-enter,
.fade-leave-to {
  transform: scale(0.8) translateY(20px);
  opacity: 0;
}

</style>

<style lang="scss">
@import "@gds/styles/global.scss";

// We need to hide chat to prevent overlay
.ArtinChatbotEWButton {
  display: none;
}
.help-widget-canvas {
  .title {
    text-align: center;
    margin: 0 0 $spacer;
    font-weight: 400;
    color: $gray-700;
  }
}
</style>
