import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import router from '@/router';

Vue.use(VueGtm, {
  id: 'GTM-5FZRPS9',
  enabled: process.env.NODE_ENV === 'production',
  router,
  source: 'https://sgtm.generali.sk/generali.js',
});
