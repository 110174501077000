const processMarketingTracking = (state, payload) => {
  const {
    product, action, additionalData, price,
  } = payload;
  const { marketingTrackingActionsSent } = state;
  const { customer } = state.calculation;
  if (!marketingTrackingActionsSent.includes(action)) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'GAevent',
      eventCategory: product,
      eventAction: action,
      eventValue: action === 'sale' ? price : 0,
      phone: `${customer.phone.prefix}${customer.phone.number}`,
      email: customer.email,
      ...additionalData,
    });
    if (window.plausible) {
      window.plausible(action, {
        props: {
          product,
        },
      });
    }
    marketingTrackingActionsSent.push(action);
  }
};

const processEcommerceLead = (payload) => {
  const dataLayer = window.dataLayer || [];
  const { price, product } = payload;

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [
          {
            name: product.name,
            price,
            brand: 'Generali',
            category: product.category,
            quantity: 1,
          },
        ],
      },
    },
  });
};

const processEcommerceSale = (payload) => {
  const dataLayer = window.dataLayer || [];
  const { price, product, policyNumber } = payload;

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'trackTrans',
    ecommerce: {
      purchase: {
        currencyCode: 'EUR',
        actionField: {
          id: policyNumber,
          affiliation: product.affiliation,
          revenue: price,
          tax: 0,
          shipping: 0,
        },
        products: [
          {
            name: product.name,
            price,
            brand: 'Generali',
            category: product.category,
            quantity: 1,
          },
        ],
      },
    },
  });
};

const generateContinuationLink = (base, id, hash) => {
  const params = new URLSearchParams({
    id,
    hash,
  });
  const link = `${base}?${params.toString()}`;
  return link;
};

export {
  processMarketingTracking,
  generateContinuationLink,
  processEcommerceSale,
  processEcommerceLead,
};
