const BASES = {
  test: {
    base: 'https://calculators-backend-test2.generali.sk/graphql',
    usedOn: [],
  },
  test1: {
    base: 'https://calculators-backend-test1.generali.sk/graphql',
    usedOn: ['webtest.generali.sk', 'siraeltest1.generali.sk', 'kz-test1.generali.sk'],
  },
  test2: {
    base: 'https://calculators-backend-test2.generali.sk/graphql',
    usedOn: ['webtest2.generali.sk', 'siraeltest2.generali.sk'],
  },
  local: {
    base: 'http://localhost:5000/graphql',
    usedOn: [],
  },
  prod: {
    base: 'https://calculators-backend.generali.sk/graphql',
    usedOn: ['www.generali.sk', 'kz.generali.sk'],
  },
};

const isTrue = (val) => val && val > 0;

const getBaseFromQueryParameter = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryOverride = urlParams.get('server');
  if (
    queryOverride
    && Object.prototype.hasOwnProperty.call(BASES, queryOverride)
  ) {
    return BASES[queryOverride].base;
  }
  return null;
};

const getBaseFromDomain = () => {
  const matchedBase = Object.values(BASES).find(
    (server) => server.usedOn.includes(window.location.hostname),
  );
  if (matchedBase) {
    return matchedBase.base;
  }
  return null;
};

const getApiBase = () => {
  const {
    VUE_APP_ALLOW_BASE_OVERRIDE_FROM_QUERY,
    VUE_APP_ALLOW_BASE_OVERRIDE_FROM_DOMAIN,
    VUE_APP_GRAPHQL_HTTP,
  } = process.env;
  if (isTrue(VUE_APP_ALLOW_BASE_OVERRIDE_FROM_QUERY) && getBaseFromQueryParameter()) {
    return getBaseFromQueryParameter();
  }

  if (isTrue(VUE_APP_ALLOW_BASE_OVERRIDE_FROM_DOMAIN) && getBaseFromDomain()) {
    return getBaseFromDomain();
  }

  if (VUE_APP_GRAPHQL_HTTP) {
    return VUE_APP_GRAPHQL_HTTP;
  }

  return BASES.test.base;
};

export default getApiBase;
