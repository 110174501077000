const scrollBehavior = (to) => {
  document.documentElement.scrollTop = 0;
  if (to.hash) {
    return {
      selector: to.hash,
    };
  }
  return { x: 0, y: 0 };
};

export default scrollBehavior;
