const isValidDate = (date) => (
  date instanceof Date && !Number.isNaN(date.getTime())
);

const getBirthDateFromNationalIdentificationNumber = (value) => {
  // Remove / and whitespace
  const cleanString = value?.replace(/[/\s]+/g, '');
  if (cleanString.length < 9) {
    return null;
  }

  const yearCode = Number(cleanString.substring(0, 2), 10);
  const monthCode = Number(cleanString.substring(2, 4), 10);
  const dayCode = Number(cleanString.substring(4, 6), 10);

  const year = (
    cleanString.length === 9
    || (yearCode >= 54 && cleanString.length === 10)
  )
    ? 1900 + yearCode
    : 2000 + yearCode;
  const month = monthCode > 50 ? monthCode - 50 : monthCode;
  const day = dayCode;

  const birthDate = new Date(`${year}-${month}-${day}`);

  return isValidDate(birthDate)
    ? birthDate
    : null;
};

export default getBirthDateFromNationalIdentificationNumber;
