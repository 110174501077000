<template>
  <div
    class="input-group"
    :class="{ '-spaced': spaced }"
  >

    <div
      v-if="!!$slots.before"
      class="before"
      :style="beforeStyles"
    >
      <slot name="before"></slot>
    </div>

    <div class="main">
      <slot>No content provided</slot>
    </div>

    <div class="suffix" v-if="suffix">
      {{ suffix }}
    </div>

    <div
      v-if="!!$slots.after"
      class="after"
      :style="afterStyles"
    >
      <slot name="after"></slot>
    </div>
  </div>
</template>

<script>
import './InputGroup.scss';

export default {
  name: 'InputGroup',
  props: {
    suffix: {
      type: String,
    },
    spaced: {
      type: Boolean,
      default: true,
    },
    beforeWidth: {
      type: Number,
    },
    afterWidth: {
      type: Number,
    },
  },
  computed: {
    beforeStyles() {
      return this.beforeWidth
        ? { width: `${this.beforeWidth}px` }
        : {};
    },
    afterStyles() {
      return this.afterWidth
        ? { width: `${this.afterWidth}px` }
        : {};
    },
  },
};
</script>
