import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import ValidationPlugin from '@gds/plugins/ValidationPlugin';
import ModalPlugin from '@gds/plugins/ModalPlugin';
import Ci306Plugin from '@/plugins/ci360';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/gtm';
import { createProvider } from './vue-apollo';

Vue.use(VueReCaptcha, { siteKey: '6LeeGYUbAAAAAEXz6hgiCpeYlkMKwJER11gkwvFb' });
Vue.use(ValidationPlugin);
Vue.use(ModalPlugin);
Vue.use(Ci306Plugin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
