<template>
  <select
    class="input-select-native"
    :name="name"
    :autocomplete="autocomplete"
    v-model="valueLocal"
    @blur="$emit('blur', $event)"
  >
    <option
      v-for="(option, optionIndex) in options"
      :value="option.value"
      :key="optionIndex"
      :disabled="option.disabled"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import './InputSelect.scss';

export default {
  name: 'InputSelectNative',
  props: {
    value: {
      type: [String, Number, Boolean],
    },
    options: {
      type: Array,
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
  },
  computed: {
    valueLocal: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit('change', value);
      },
    },
  },
};
</script>
