const getActivePromo = (code, promos) => {
  const now = new Date();
  let match = null;
  match = promos.find(
    (promo) => promo.type === 'BASE' && now > promo.conditions.start && now < promo.conditions.end,
  );
  if (code) {
    match = promos.find(
      (promo) => promo.type === 'CODE'
        && promo.conditions.code === code
        && now > promo.conditions.start
        && now < promo.conditions.end,
    );
  }
  return match;
};

export default getActivePromo;
