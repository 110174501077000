<template>
  <p class="base-paragraph">
    <slot></slot>
  </p>
</template>

<script>
import './BaseParagraph.scss';

export default {
  name: 'BaseParagraph',
};
</script>
