<template>
  <div id="app">
    <CalculatorStep
      title="Povinné zmluvné poistenie"
      :titleLink="titleLink"
      :progress="headerStepProgress"
      :hasBackButton="hasBackButton"
      :hasContinueButton="hasContinueButton"
      :continueButtonText="continueButtonText"
      :price="headerPrice"
      @click-back="navigateBack()"
      @click-proceed="tryProceed()"
    >
      <RouterView
        ref="stepContents"
      />
    </CalculatorStep>
    <HelpWidget
      :callbackProps="{
        customerPhone: customer.phone,
        product: 'PZP17',
        continuationLink: $store.getters.continuationLink,
        endpoint: callbackEndpoint,
      }"
    />
    <ModalWindow
      name="globalRecaptcha"
      title="Potvrďte, prosím"
    >
      <VueRecaptcha
        sitekey="6LdiJYUbAAAAAB0SuwEJfjRIbkW9JtqFq1Ccftgx"
        @verify="recaptchaVerify"
      />
    </ModalWindow>
  </div>
</template>

<script>
import '@gds/styles/canvas.scss';
import { mapGetters } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

import getActivePromo from '@/util/getActivePromo';
import {
  loadCalculation,
  loadCustomerDetails,
  createNewCalculation,
  tryProceed,
} from '@gds/common/calculators/fns/inits';

import CalculatorStep from '@gds/components/CalculatorStep';
import ModalWindow from '@gds/components/ModalWindow';
import HelpWidget from '@gds/common/calculators/HelpWidget';

import { httpEndpoint } from '@/vue-apollo';
import VERIFY_CAPTCHA from '@/graphql/VerifyCaptcha.gql';

export default {
  name: 'App',
  components: {
    VueRecaptcha,
    CalculatorStep,
    ModalWindow,
    HelpWidget,
  },
  computed: {
    ...mapGetters([
      'customer',
      'currentPrice',
      'priceIsLoading',
      'productProps',
    ]),
    headerStepProgress() {
      const stepMap = {
        0: 0,
        1: 0,
        2: 1,
        3: 2,
        4: 3,
        5: 4,
        6: 5,
        7: 5,
      };
      const steps = [
        'Auto',
        'Vaše údaje',
        'Cena',
        'O vás',
        'Doplnenie',
        'Zhrnutie',
      ];
      const current = stepMap[this.$route.meta.step];
      return {
        steps,
        current,
      };
    },
    headerPrice() {
      return this.currentPrice || this.priceIsLoading ? {
        isLoading: this.priceIsLoading,
        amount: this.currentPrice,
        frequency: this.productProps.frequency,
      } : null;
    },
    hasContinueButton() {
      const isOnLaterScreen = this.$route.meta.step > 0;
      const isOnFirstScreenValid = this.$store.state.progress.stepReached > 0;
      const isOnAuthorizePrefilledOfferScreen = this.$route.name === 'AuthorizePrefilledOffer';
      return isOnLaterScreen || isOnFirstScreenValid || isOnAuthorizePrefilledOfferScreen;
    },
    hasBackButton() {
      const { returnTo } = this.$store.state.progress;
      const isOnLaterScreen = this.$route.meta.step > 0;
      return isOnLaterScreen && returnTo === null;
    },
    continueButtonText() {
      const { returnTo } = this.$store.state.progress;
      return returnTo !== null
        ? 'Späť na zhrnutie'
        : this.$route.meta.continueButton;
    },
    titleLink: () => process.env.VUE_APP_FRONTEND_BASE,
    callbackEndpoint: () => `${httpEndpoint.replace('graphql', 'api')}/v1/callme`,
  },
  methods: {
    loadPromo() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('kampan');
      const activePromo = getActivePromo(code);
      if (activePromo && activePromo.overrides?.obtainerId) {
        this.$store.dispatch('setObtainer', {
          apollo: this.$apollo,
          obtainerId: activePromo.overrides?.obtainerId,
        });
      }
      this.$store.state.activePromo = activePromo;
    },
    async loadPrefilledOffer() {
      const urlParams = new URLSearchParams(window.location.search);
      const offerId = urlParams.get('ponuka');
      if (offerId) {
        this.$store.state.prefilledOffer.id = offerId;
        const loaded = await this.$store.dispatch('loadPrefilledOfferGreeting', this.$apollo);
        this.$store.state.prefilledOffer.isActive = loaded;
      }
    },
    loadCalculation() {
      loadCalculation(this, {
        setStepReached: 3,
        pushStep: '/3',
      });
    },
    loadCustomerDetails() {
      loadCustomerDetails(this.$store, this.$apollo);
    },
    async createNewCalculation() {
      createNewCalculation(this);
    },
    navigateBack() {
      this.$router.back();
    },
    tryProceed() {
      tryProceed(this);
    },
    hideRecaptchaBadge() {
      this.$recaptchaInstance.hideBadge();
    },
    async recaptchaVerify(token) {
      const result = await this.$apollo.mutate({
        mutation: VERIFY_CAPTCHA,
        variables: {
          token,
          type: 'calculation_update',
        },
      });
      if (result) {
        this.$store.state.miscFlags.isBackendConnected = true;
        this.$modal.hide('globalRecaptcha');
        this.$router.back();
        this.$router.forward();
      }
    },
    watchForClose() {
      window.addEventListener('beforeunload', () => {
        this.$sendToCI('END_SESSION');
      });
    },
  },
  async mounted() {
    await this.$recaptchaLoaded();
    this.hideRecaptchaBadge();
    this.loadPromo();
    this.loadPrefilledOffer();
    this.loadCalculation();
    this.loadCustomerDetails();
    this.createNewCalculation();
    this.watchForClose();
  },
};

</script>
