const getPartyFullName = (party) => {
  switch (party.type) {
    case 'PERSON':
      return `${party.titleBeforeName} ${party.firstName} ${party.lastName}`;
    case 'SELFEMPLOYED':
      return `${party.titleBeforeName} ${party.firstName} ${party.lastName} - ${party.businessName}`;
    case 'COMPANY':
      return party.businessName;
    default:
      return '';
  }
};

const getPartyReadableAddress = (party) => (
  `${party.street} ${party.streetNumber}, ${party.postalCode} ${party.town}`
);

const getPartyReadableDetails = (party) => {
  const hasCorrespondenceAddress = !!party.correspondenceAddress.postalCode;
  switch (party.type) {
    case 'COMPANY':
      switch (hasCorrespondenceAddress) {
        case true:
          return `
            IČO: ${party.companyRegistrationNumber}<br>
            Adresa sídla: ${getPartyReadableAddress(party.address)}<br>
            Korešpodenčná adresa: ${getPartyReadableAddress(party.correspondenceAddress)}<br>
          `;
        default:
          return `
            IČO: ${party.companyRegistrationNumber}<br>
            Adresa sídla: ${getPartyReadableAddress(party.address)}<br>
          `;
      }
    default:
      switch (hasCorrespondenceAddress) {
        case true:
          return `
            RČ: ${party.nationalIdentificationNumber}<br>
            Doklad: ${party.documentNumber} (${party.documentType})<br>
            Štátna príslušnosť: ${party.nationality}<br>
            Adresa trvalého bydliska: ${getPartyReadableAddress(party.address)}<br>
            Korešpodenčná adresa: ${getPartyReadableAddress(party.correspondenceAddress)}<br>
          `;
        default:
          return `
            RČ: ${party.nationalIdentificationNumber}<br>
            Doklad: ${party.documentNumber} (${party.documentType})<br>
            Štátna príslušnosť: ${party.nationality}<br>
            Adresa trvalého bydliska: ${getPartyReadableAddress(party.address)}<br>
          `;
      }
  }
};

export {
  getPartyFullName,
  getPartyReadableAddress,
  getPartyReadableDetails,
};
