import store from '@/store';

const ciConfig = {
  debug: !!process.env.VUE_APP_DEBUG_MODE,
};

// Helpers
const readableBoolean = (val) => (val ? 'Áno' : 'Nie');

const readableBooleanBlankNegative = (val) => (val ? 'Áno' : '');

const removeWhitespace = (value) => value.replace(/\s+/g, '');

const transformPartyType = (type) => {
  const map = {
    PERSON: 'FO',
    COMPANY: 'PO',
    SELFEMPLOYED: 'FO_PODNIKATEL',
  };
  return map[type];
};

const cleanBirthYear = (year, partyType) => {
  if (partyType !== 'PERSON') {
    return '';
  }
  const parsed = parseInt(year, 10);
  if (!Number.isNaN(parsed)) {
    return parsed;
  }
  return '';
};

const nullToEmpty = (value) => (value === null ? '' : value);

const objectNullToEmpty = (input) => {
  const output = {};
  Object.entries(input).forEach(([key, value]) => {
    output[key] = nullToEmpty(value);
  });
  return output;
};

const emptyToFallback = (value) => (value || 'NOT_PROVIDED');

const simplifyVehicle = (originalVehicle) => {
  const vehicle = objectNullToEmpty(originalVehicle);
  delete vehicle.makeMap;
  delete vehicle.modelMap;
  return vehicle;
};

const simplifyPrices = (originalPrices) => originalPrices.map((item) => ({
  frequency: item.frequency,
  package: item.package,
  mainPrice: item.prices.mainPrice.toFixed(2),
}));

const getCurrentPrice = (price) => price || null;

const getAllPrices = (matrix) => {
  if (matrix.length) {
    return JSON.stringify(simplifyPrices(matrix));
  }
  return '';
};

const getObtainerId = (obtainerId, isCotinued, activePromo) => {
  if (isCotinued) {
    return '';
  }
  if (activePromo?.overrides?.ci360?.hideObtainerId) {
    return '';
  }
  if (obtainerId) {
    return obtainerId;
  }
  return '';
};

const getContinuationLink = () => {
  if (store.state.calculation.final.policyNumber) {
    return '';
  }
  if (!store.getters.currentPrice) {
    return '';
  }
  return store.getters.continuationLink;
};

const getSourceName = (lob, activePromo) => {
  const isProd = process.env.VUE_APP_GRAPHQL_HTTP && process.env.VUE_APP_GRAPHQL_HTTP.includes('calculators-backend.generali.sk');

  const list = [];
  list.push(lob);
  list.push('KALKULACIA');
  list.push(isProd ? 'PROD' : 'TEST');
  if (activePromo && activePromo.overrides?.appendSourceName) {
    list.push(activePromo.id);
  }

  return list.join('_');
};

// Function
const sendToCI = (specialEvent) => {
  const {
    calculation, progress, activePromo, miscFlags,
  } = store.state;
  const {
    customer, vehicle, agreements, prices, productProps, idd,
  } = calculation;

  if (specialEvent === 'CALLBACK') {
    miscFlags.isCallbackSent = true;
  }

  /* eslint-disable quote-props */
  const ciPayload = {
    'eventName': 'custom',
    'apiEventKey': 'ci360_online_calc_lead',
    'customGroupName': 'web lead',
    'customName': 'calculation',

    'prilezitost_id': calculation.id,
    'typ_zdroja': 'WEB_KALKULACIA',
    'nazov_kalkulacka': getSourceName('PZP', activePromo),
    'znacka_spolocnosti': 'GENERALI',
    'datum_vytvorenia': store.getters.createdAtISO,
    'url_dokoncenie_kalkulacie': getContinuationLink(),
    'krok_vzniku': progress.stepReached,
    'referral_vzniku': document.referrer,
    'dokonceny_nakup': readableBoolean(!!calculation.final.policyNumber),
    'ukoncena_session': readableBoolean(specialEvent === 'END_SESSION'),
    'backend_spojenie': readableBoolean(miscFlags.isBackendConnected),
    'callback': readableBooleanBlankNegative(miscFlags.isCallbackSent),

    'pravna_forma': transformPartyType(customer.type),
    'nazov_spolocnosti': customer.businessName,
    'telefon': `${customer.phone.prefix}${customer.phone.number}`,
    'email': emptyToFallback(customer.email),
    'rc': customer.nationalIdentificationNumber,
    'rok_narodenia': cleanBirthYear(customer.birthYear, customer.type),
    'ico': customer.companyRegistrationNumber,

    'mkt_suhlas': readableBooleanBlankNegative(agreements.marketingAgreement),
    'zc_kalkulacka': getObtainerId(calculation.obtainerId, calculation.isContinued, activePromo),

    'titul_pred': customer.titleBeforeName,
    'meno': customer.firstName,
    'priezvisko': customer.lastName,

    'psc_trv': removeWhitespace(customer.address.postalCode),
    'obec_trv': customer.address.town,
    'ulica_trv': customer.address.street,
    'orientacne_cislo_trv': customer.address.streetNumber,

    'psc_kor': removeWhitespace(customer.correspondenceAddress.postalCode),
    'obec_kor': customer.correspondenceAddress.town,
    'ulica_kor': customer.correspondenceAddress.street,
    'orientacne_cislo_kor': customer.correspondenceAddress.streetNumber,

    'datum_od': store.getters.startDateISO,
    'frekvencia_platby': productProps.frequency,
    'zvoleny_balik': productProps.package,
    'cena_zvoleny_balik': getCurrentPrice(store.getters.currentPrice),
    'ceny_balikov': getAllPrices(prices.matrix),

    'ecv_vozidla': vehicle.licensePlate,
    'znacka_vozidla': store.getters.vehicleReadableMake,
    'model_vozidla': store.getters.vehicleReadableModel,

    'lokalita_psc_poistnika': removeWhitespace(customer.address.postalCode),

    'predmet_poistenia_info': JSON.stringify({
      ...simplifyVehicle(vehicle),
      holderCausedDamage: nullToEmpty(productProps.holderCausedDamage),
      duplicateInsurance: nullToEmpty(idd.duplicateInsurance),
      collisionInsurance: nullToEmpty(idd.collisionInsurance),
    }),
  };

  if (ciConfig.debug) {
    console.log('CI360 payload:', ciPayload);
  }

  if (window.ci360) {
    window.ci360('send', ciPayload);
  }
};

/* eslint-disable no-param-reassign */
const Ci360Plugin = {
  install(Vue) {
    Vue.prototype.$sendToCI = sendToCI;
  },
};

export default Ci360Plugin;
